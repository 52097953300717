import { handleUnauthorizedError } from '../utils';
import microservices from './microservices';
export async function getFacias(url) {
    try {
        const { data } = await microservices.facia.get('/' + url);
        return data.data;
    } catch (e) {
        handleUnauthorizedError(e);
        return [];
    }
}
