import { Box, FormControlLabel, Grid, Paper, Snackbar, Switch } from '@material-ui/core';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useReducer } from 'react';
import ReactConfirmAlert from 'react-confirm-alert';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DangerButton from '../../components/button/Danger';
import DefaultButton from '../../components/button/Default';
import PrimaryButton from '../../components/button/Primary';
import SecondaryButton from '../../components/button/Secondary';
import { addDevice, deleteDevice, getDevice, updateDevice } from '../../services/device.service';
import { getFacias } from '../../services/fascia.service';
import { getAllNestedValues, resolve } from '../../utils';
import Alert, { useAlert } from './components/Alert';
import DevicesHeader from './components/DevicesHeader';
import FormSelect from './components/FormSelect';
import FormTextField from './components/FormTextField';
import { DeviceSchema, emptyDevice } from './Device.schema';

const defaultState = {
    loading: true,
    device: emptyDevice,
    fasciaList: [],
    viewMode: 'ADD', // Whether user is adding or editing a device
    confirmDelete: false,
};

const deviceReducer = (state = defaultState, { type, payload }) => {
    switch (type) {
        case 'LOADED':
            return { ...state, loading: false };
        case 'FETCH_DEVICE_REQUEST':
            return { ...state, loading: true };
        case 'FETCH_DEVICE_SUCCESS':
            return { ...state, device: payload, loading: false, viewMode: 'EDIT' };
        case 'FETCH_FACIAS_SUCCESS':
            // eslint-disable-next-line no-case-declarations
            let fasciaList = [];
            payload.map(item => {
                if (item.accountId) {
                    fasciaList.push({ value: item.ID, label: item.accountId });
                }
            });
            return { ...state, fasciaList: fasciaList, loading: false };
        case 'FETCH_DEVICE_FAILURE':
            return { ...state, device: undefined, loading: false };
        case 'TOGGLE_DELETE_CONFIRMATION':
            return { ...state, confirmDelete: !state.confirmDelete, viewMode: 'EDIT' };
        default:
            return state;
    }
};

const PAYMENT_METHODS = {
    CARD: 'card',
    CASH: 'cash',
};

const DEVICE_PAYMENT_METHODS = {
    kiosk: [PAYMENT_METHODS.CASH, PAYMENT_METHODS.CARD],
    webtill: [PAYMENT_METHODS.CASH],
    ipad: [PAYMENT_METHODS.CASH],
};

const isSupportedPaymentMethod = (device, method) =>
    !!DEVICE_PAYMENT_METHODS[device] && DEVICE_PAYMENT_METHODS[device].includes(method);

const DevicePage = ({ match, currentUser }) => {
    const { params } = match;
    const { id: deviceID, locationID } = params;

    const alert = useAlert();
    const history = useHistory();
    const [state, dispatch] = useReducer(deviceReducer, defaultState);
    const { viewMode } = state;

    const toggleDeleteConfirmation = () => dispatch({ type: 'TOGGLE_DELETE_CONFIRMATION' });
    useEffect(() => {
        getFacias('list')
            .then(data => {
                dispatch({ type: 'FETCH_FACIAS_SUCCESS', payload: data });
            })
            .catch(() => dispatch({ type: 'FETCH_DEVICE_FAILURE' }));
    }, []);
    /* Fetch the device if we're editing an existing device */
    useEffect(() => {
        if (deviceID) {
            dispatch({ type: 'FETCH_DEVICE_REQUEST' });
            getDevice({ deviceID, locationID })
                .then(data => dispatch({ type: 'FETCH_DEVICE_SUCCESS', payload: data }))
                .catch(() => dispatch({ type: 'FETCH_DEVICE_FAILURE' }));
        } else {
            dispatch({ type: 'LOADED' });
        }
    }, [deviceID, locationID]);

    const initialFormState = useMemo(() => {
        const result = {
            ...emptyDevice,
            ...state.device,
        };

        // If the device has not been updated since the cash and card options were introduced,
        // add the card and cash options based on the defaults for the device type
        const cardOption = resolve('enableCardPayments', state.device);
        const cashOption = resolve('enableCashPayments', state.device);

        if (typeof cardOption === 'undefined') {
            result.enableCardPayments = isSupportedPaymentMethod(
                state.device.type,
                PAYMENT_METHODS.CARD,
            );
        }

        if (typeof cashOption === 'undefined') {
            result.enableCashPayments = isSupportedPaymentMethod(
                state.device.type,
                PAYMENT_METHODS.CASH,
            );
        }

        result.enabled = result.enabled ?? true;

        return result;
    }, [state.device]);

    const saveChanges = async (form, params) => {
        if (state.viewMode === 'ADD') {
            try {
                await addDevice(form);
                history.push('/devices');
            } catch (e) {
                alert.open('Failed to create device');
            }
        } else {
            const changedFormValues = getChangedFormValues(state.device, form);
            const updatedDevice = await updateDevice(changedFormValues, params);
            if (updatedDevice) {
                history.push('/devices');
            }
        }
    };

    /**
     * Compares two objects and returns the properties which are different
     * between the two.
     *
     * @param {Object} originalObject
     * @param {Object} modifiedObject
     * @returns Object
     */
    const getChangedFormValues = (originalObject, modifiedObject) => {
        return Object.keys(modifiedObject).reduce((diff, key) => {
            if (originalObject[key] === modifiedObject[key]) {
                return diff;
            }
            return {
                ...diff,
                [key]: modifiedObject[key],
            };
        }, {});
    };

    const onSubmit = async (values, actions) => {
        const form = JSON.parse(JSON.stringify(values));
        try {
            await saveChanges(form, params);
        } catch (e) {
            actions.setSubmitting(false);
            alert.open('Failed to save device');
        }
    };

    const onDelete = async values => {
        const form = JSON.parse(JSON.stringify(values));
        const deviceID = form.values.ID;
        try {
            await deleteDevice(deviceID);
            history.push('/devices');
        } catch (e) {
            alert.open('Failed to delete device');
        }
    };

    const getPageActions = ({ errors, isSubmitting, dirty }) => {
        const hasErrors = errors ? Object.values(errors).length > 0 : false;
        const canSave = !isSubmitting && !hasErrors && !!dirty;

        const SaveButtonType = canSave ? PrimaryButton : SecondaryButton;
        const SaveButton = (
            <SaveButtonType
                key="save-changes"
                // Only make the button a submit button if there's no errors
                type={canSave ? 'submit' : 'button'}
                onClick={e => {
                    if (hasErrors) {
                        e.preventDefault();
                        const errorMessages = getAllNestedValues(errors);
                        alert.open(...errorMessages);
                    }
                }}
                disabled={isSubmitting}
            >
                Save Changes
            </SaveButtonType>
        );

        const DeleteButton = (
            <DangerButton key="delete-device" onClick={toggleDeleteConfirmation}>
                Delete Device
            </DangerButton>
        );

        const actions = [SaveButton];

        if (viewMode === 'EDIT' && currentUser.permissions.includes('devices--delete')) {
            actions.unshift(DeleteButton);
        }

        return actions;
    };
    const handleFasciaChanged = ({ event, form }) => {
        event.persist();
        const type = event.target.value;
        form.setFieldValue('fascia', type, true);
    };
    const handleDeviceTypeChanged = ({ event, form }) => {
        event.persist();

        const type = event.target.value;
        form.setFieldValue('type', type, true);

        selectDefaultPaymentMethods({ form, deviceType: type });
    };

    /** Enable or disable payment types, depending on if they are supported for the given device type */
    const selectDefaultPaymentMethods = ({ form, deviceType }) => {
        const { CASH, CARD } = PAYMENT_METHODS;
        const fields = [
            ['enableCardPayments', isSupportedPaymentMethod(deviceType, CARD)],
            ['enableCashPayments', isSupportedPaymentMethod(deviceType, CASH)],
        ];
        for (let [field, value] of fields) {
            form.setFieldValue(field, value, true);
        }
    };

    const toggleItemStyle = {
        color: '#3e4555',
        fontFamily: 'Gotham',
        fontSize: 14,
        fontWeight: 'bold',
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialFormState}
            validationSchema={DeviceSchema}
            validateOnMount={true}
            onSubmit={onSubmit}
        >
            {form => {
                const disableCashPayment = !isSupportedPaymentMethod(
                    form.values.type,
                    PAYMENT_METHODS.CASH,
                );
                const disableCardPayment = !isSupportedPaymentMethod(
                    form.values.type,
                    PAYMENT_METHODS.CARD,
                );

                return (
                    <form onSubmit={form.handleSubmit}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="spaceBetween"
                            height="100%"
                        >
                            <DevicesHeader
                                leftItems={[
                                    <DefaultButton onClick={history.goBack} key="back">
                                        Go Back
                                    </DefaultButton>,
                                ]}
                                rightItems={getPageActions(form)}
                            />
                            {state.confirmDelete && (
                                <ReactConfirmAlert
                                    customUI={() => {
                                        return (
                                            <Box component={Paper} style={{ padding: 32 }}>
                                                <h1>Delete Device?</h1>
                                                <p>
                                                    Are you sure you want to permanently delete this
                                                    device?
                                                </p>
                                                <DefaultButton onClick={toggleDeleteConfirmation}>
                                                    Cancel
                                                </DefaultButton>
                                                <DangerButton onClick={() => onDelete(form)}>
                                                    Delete
                                                </DangerButton>
                                            </Box>
                                        );
                                    }}
                                />
                            )}
                            <Snackbar
                                open={alert.isOpen}
                                autoHideDuration={10000}
                                onClose={alert.onClose}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            >
                                <Alert onClose={alert.onClose} severity="error">
                                    {alert.message.map(message => {
                                        return <div key={message}>{message}</div>;
                                    })}
                                </Alert>
                            </Snackbar>
                            <Box
                                flex={1}
                                mx={6}
                                my={1}
                                display="flex"
                                flexDirection="column"
                                justifyContent="baseline"
                            >
                                <h2>
                                    {state.viewMode === 'ADD' ? 'Add Device' : state.device.name}
                                </h2>
                                <Box>
                                    <Grid container spacing={8}>
                                        <Grid item xs={3}>
                                            <Grid container spacing={2} direction="column">
                                                <Grid item>
                                                    <FormTextField name="ID" label="Device ID" />
                                                </Grid>
                                                <Grid item>
                                                    <FormTextField
                                                        name="name"
                                                        label="Device Name"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormSelect
                                                        name="type"
                                                        label="Device Type"
                                                        options={[
                                                            { label: 'Kiosk', value: 'kiosk' },
                                                            { label: 'Webtill', value: 'webtill' },
                                                            { label: 'iPad', value: 'ipad' },
                                                        ]}
                                                        onChange={event => {
                                                            handleDeviceTypeChanged({
                                                                event,
                                                                form,
                                                            });
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormTextField
                                                        name="locationID"
                                                        label="Store Number"
                                                        defaultHelperText="For Example: 040"
                                                        disabled={state.viewMode === 'EDIT'}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormSelect
                                                        name="fascia"
                                                        label="Fascia"
                                                        options={state.fasciaList}
                                                        onChange={event => {
                                                            handleFasciaChanged({
                                                                event,
                                                                form,
                                                            });
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormSelect
                                                        name="currency"
                                                        label="Payment Currency"
                                                        fullWidth={false}
                                                        options={[{ label: 'GBP', value: 'GBP' }]}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <label style={toggleItemStyle}>
                                                        Payment Methods
                                                    </label>
                                                    <div>
                                                        <FormControlLabel
                                                            label="Cash"
                                                            control={
                                                                <Switch
                                                                    checked={
                                                                        form.values
                                                                            .enableCashPayments
                                                                    }
                                                                    onChange={e => {
                                                                        form.setFieldValue(
                                                                            'enableCashPayments',
                                                                            e.target.checked,
                                                                            true,
                                                                        );
                                                                    }}
                                                                    color="primary"
                                                                    name="Cash"
                                                                    inputProps={{
                                                                        'aria-label':
                                                                            'primary checkbox',
                                                                    }}
                                                                    disabled={disableCashPayment}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <FormControlLabel
                                                            label="Card"
                                                            control={
                                                                <Switch
                                                                    checked={
                                                                        form.values
                                                                            .enableCardPayments
                                                                    }
                                                                    onChange={e => {
                                                                        form.setFieldValue(
                                                                            'enableCardPayments',
                                                                            e.target.checked,
                                                                            true,
                                                                        );
                                                                    }}
                                                                    color="primary"
                                                                    name="Card"
                                                                    inputProps={{
                                                                        'aria-label':
                                                                            'primary checkbox',
                                                                    }}
                                                                    disabled={disableCardPayment}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                                {form.values.enableCardPayments && (
                                                    <Grid item>
                                                        <FormTextField
                                                            name="poiID"
                                                            label="Payment Terminal Serial Number"
                                                            defaultHelperText="For Example: P400Plus-123456789"
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <label style={toggleItemStyle}>
                                                        Enable / Disable Device
                                                    </label>
                                                    <div>
                                                        <FormControlLabel
                                                            label={
                                                                form.values.enabled
                                                                    ? 'Enabled'
                                                                    : 'Disabled'
                                                            }
                                                            control={
                                                                <Switch
                                                                    checked={form.values.enabled}
                                                                    onChange={e => {
                                                                        form.setFieldValue(
                                                                            'enabled',
                                                                            e.target.checked,
                                                                            true,
                                                                        );
                                                                    }}
                                                                    color="primary"
                                                                    name="enabled"
                                                                    inputProps={{
                                                                        'aria-label':
                                                                            'primary checkbox',
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <label style={toggleItemStyle}>
                                                        Enable / Disable MPOS Button
                                                    </label>
                                                    <div>
                                                        <FormControlLabel
                                                            label={
                                                                form.values.mposEnabled
                                                                    ? 'Enabled'
                                                                    : 'Disabled'
                                                            }
                                                            control={
                                                                <Switch
                                                                    checked={
                                                                        form.values.mposEnabled
                                                                    }
                                                                    onChange={e => {
                                                                        form.setFieldValue(
                                                                            'mposEnabled',
                                                                            e.target.checked,
                                                                            true,
                                                                        );
                                                                    }}
                                                                    color="primary"
                                                                    name="mposEnabled"
                                                                    inputProps={{
                                                                        'aria-label':
                                                                            'primary checkbox',
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </form>
                );
            }}
        </Formik>
    );
};

function mapStateToProps(state) {
    return {
        currentUser: state.auth.currentUser,
    };
}

export default connect(mapStateToProps)(DevicePage);
